'use client';

import { enUS } from '@clerk/localizations';
import { ClerkProvider } from '@clerk/nextjs';
import { NuqsAdapter } from 'nuqs/adapters/next/app';
import type { ReactNode } from 'react';

import { Route } from '@/constants/routes';

import { CSPostHogProvider } from './PostHogProvider';
import { ReactQueryProvider } from './ReactQueryProvider';

interface ProvidersProps {
  children: ReactNode;
  username: string;
  orgId?: string;
  name: string;
  userId: string;
}

export function Providers({
  children,
  username,
  orgId,
  name,
  userId,
}: ProvidersProps) {
  return (
    <ReactQueryProvider>
      <CSPostHogProvider
        username={username}
        organizationId={orgId}
        name={name}
        userId={userId}
      >
        <ClerkProvider
          localization={enUS}
          signInUrl={Route.SignIn}
          signUpUrl={Route.SignUp}
          signInFallbackRedirectUrl={Route.Dashboard}
          signUpFallbackRedirectUrl={Route.Welcome}
        >
          <NuqsAdapter>{children}</NuqsAdapter>
        </ClerkProvider>
      </CSPostHogProvider>
    </ReactQueryProvider>
  );
}
